import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { formatCurrency } from '@additive-apps/ui/utils/format-currency';
/**
 * Takes a voucher, builds the correct pricing depending on type,
 * discount and amount and formats to german locale
 * @class av-amount-display
 * @module voucher-helpers
 */
export default Helper.extend({
  intl: service(),

  compute([voucher, currency, language]) {
    if (voucher && voucher.amount > 0) {
      const { isDiscounted, amount, discountedAmount, discountedPercentage } = voucher;

      const formattedAmount = formatCurrency({
        value: amount,
        currency,
        locales: language,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        divider: 1
      });

      const formattedDiscount = formatCurrency({
        value: discountedAmount,
        currency,
        locales: language,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        divider: 1
      });

      if (discountedPercentage === 100) {
        return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithIncentive', {
          amount: formattedAmount
        });
      } else if (isDiscounted) {
        return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithDiscount', {
          amount: formattedAmount,
          discount: formattedDiscount || 0
        });
      }

      return this.intl.t('dashboard.newOrderDialog.voucherSelectDialog.amountWithoutDiscount', {
        amount: formattedAmount
      });
    }

    return '';
  }
});
