import ENV from 'additive-voucher/config/environment';
import AuthorizationAdapter from '@additive-apps/auth/adapters/application';
import { dasherize } from '@ember/string';

import { inject as service } from '@ember/service';

export default class ApplicationAdapter extends AuthorizationAdapter {
  @service currentUser;
  @service uiAppSettings;

  host = ENV.APP.apiBaseHost;

  get currentLocale() {
    return this.uiAppSettings?.currentLocale;
  }

  get headers() {
    const headers = {
      Accept: 'application/vnd.additive+json; version=2',
      'Accept-Language': this.currentLocale || 'de',
      'Content-Type': 'application/json'
    };

    return Object.assign({}, super.headers, headers);
  }

  get namespace() {
    return `${this.currentUser?.currentOrganization?.id}`;
  }

  pathForType() {
    return dasherize(super.pathForType(...arguments));
  }
}
